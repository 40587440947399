import { Storage } from "aws-amplify";
import { S3ProviderListOutputItem } from "@aws-amplify/storage";
import { loggingWarn } from "../utils/LoggingUtils";

Storage.configure({
    customPrefix: {
        public: "",
    },
});

/**
 * ファイル一覧の取得
 * @param email メールアドレス
 * @returns
 */
export const getFileList = async (email: string) => {
    try {
        const files = await Storage.list(email + "/", {
            level: "public",
            pageSize: "ALL",
        });
        return files["results"];
    } catch (error) {
        loggingWarn("[S3 list] Failed:", error);
        throw error;
    }
};

/**
 * フォルダの作成
 * @param email メールアドレス
 * @param folderName フォルダ名（メールアドレスに続くプロジェクト名）
 */
export const createFolder = async (email: string, folderName: string | undefined) => {
    try {
        let key = email + "/";
        if (folderName) {
            const cleanedFolderName = folderName.replace(/\/+$/, "");
            key += cleanedFolderName + "/";
        }
        Storage.put(key, "");
    } catch (error) {
        loggingWarn("[S3 put] Failed:", error);
        throw error;
    }
};

/**
 * S3からファイルをダウンロード
 * @param {*} key
 * @returns
 */
export const getFile = async (key: string, filename: string) => {
    try {
        const result = await Storage.get(key, { download: true });
        if (result.Body) {
            downloadBlob(result.Body, filename);
        }
    } catch (error) {
        loggingWarn("[S3 get] Failed:", error);
        throw error;
    }
};

/**
 * S3からファイルの画像URL取得
 * @param {*} key
 * @returns
 */
export const getUrl = async (key: string) => {
    var signedUrl = "";
    try {
        signedUrl = await Storage.get(key, { level: "public" });
    } catch (error) {
        loggingWarn("[S3 get] Failed:", error);
        throw error;
    }
    return signedUrl;
};

/**
 * S3からファイルの画像URL取得
 * @param {*} key
 * @returns
 */
export const getJsonObject = async (key: string) => {
    try {
        const fileUrl = await Storage.get(key, { download: true });
        const text = await new Response(fileUrl.Body).text();
        const json = JSON.parse(text);
        return json;
    } catch (error) {
        loggingWarn("[S3 get] Failed:", error);
        throw error;
    }
};

/**
* S3へファイルをアップロード
* @param key
* @param file
* @returns
*/
export const putFile = async (
    key: string,
    file: File,
    onProgress?: (progress: number) => void
) => {
    try {
        const result = await Storage.put(key, file, {
            contentType: file.type,
            progressCallback: (progress: { loaded: number; total: number }) => {
                if (onProgress) {
                    const percentage = Math.round((progress.loaded / progress.total) * 100);
                    console.log("putFile() : ", percentage, "%, Uploaded: ", progress.loaded, ", Total: ", progress.total);
                    onProgress (percentage);
                }
            },
        });
        return result;
    } catch (error) {
        loggingWarn("[S3 put] Failed:", error);
        throw error;
    }
};

/**
 * S3からファイルを削除
 * @param {*} key
 * @returns
 */
export const removeFile = async (key: string) => {
    try {
        await Storage.remove(key);
    } catch (error) {
        loggingWarn("[S3 remove] Failed:", error);
        throw error;
    }
};

/**
 * ファイルリスト取得結果からプロジェクト名一覧を抽出する。
 *
 * ※S3の対象バケットにて、
 * バケット名/[メールアドレス]/[プロジェクト名]/[ファイル名]
 * の構成でファイルが保存されているとする。
 * @param {*} fileList
 * @returns
 */
export const extractProjects = (fileList: S3ProviderListOutputItem[]) => {
    const projectList: string[] = [];
    fileList
        .filter((res) => res.key?.split("/")[1])
        .forEach((res) => {
            const projectName = res.key?.split("/")[1];
            if (projectName && !projectList.includes(projectName)) {
                projectList.push(projectName);
            }
        });
    return projectList;
};

/**
 * ファイルリスト取得結果から指定したプロジェクト配下のファイルを抽出する
 *
 * @param {*} fileList
 * @param {*} projectName
 * @returns
 */
export const extractFilesByProject = (fileList: S3ProviderListOutputItem[], projectName: string) => {
    const newList: S3ProviderListOutputItem[] = [];
    fileList
        .filter((res) => res.size && res.size > 0) // ファイルに限定
        .filter((res) => projectName == res.key?.split("/")[1]) // プロジェクト名で絞り込み
        .forEach((res) => {
            newList.push(res);
        });
    return newList;
};

/**
 * ファイルリスト取得結果から指定したプロジェクトに指定したファイルが存在するか確認
 *
 * @param {*} fileList
 * @param {*} projectName
 * @param {*} targetFileName
 * @returns
 */
export const ifExistInSpecifiedProject = (
    fileList: S3ProviderListOutputItem[],
    projectName: string,
    targetFileName: string | undefined,
) => {
    return fileList
        .filter((res) => res.size && res.size > 0) // ファイルに限定
        .filter((res) => projectName == res.key?.split("/")[1]) // プロジェクト名で絞り込み
        .map((res) => res.key?.split("/")[2]) // ファイル名を抽出
        .some((name) => name == targetFileName);
};

/**
 * S3から取得したファイル情報からダウンロードを実行
 *
 * @param blob
 * @param filename
 * @returns
 */
export function downloadBlob(blob: Blob, filename: string) {
    const url = URL.createObjectURL(blob);
    const a = document.createElement("a");
    a.href = url;
    a.download = filename || "download";
    const clickHandler = () => {
        setTimeout(() => {
            URL.revokeObjectURL(url);
            a.removeEventListener("click", clickHandler);
        }, 150);
    };
    a.addEventListener("click", clickHandler, false);
    a.click();
    return a;
}
