import { useTranslation } from "react-i18next";
import Modal from "react-modal";
import { EMAIL_MAX_LENGTH, PROJECT_NAME_MAX_LENGTH } from "../../consts/constants";
import { ChangeEventHandler, FormEventHandler, useEffect, useState } from "react";
import { shareFile } from "../../clients/S3ClientNew";
import { CrossCircleIcon } from "../../components/Icons";
import { loggingError } from "../../utils/LoggingUtils";
import { S3ProviderListOutputItem } from "@aws-amplify/storage";

type ModalProps = {
    showModal: boolean;
    handleClose: () => void;
    selectedFile: S3ProviderListOutputItem | undefined;
    selectedProject: string;
};

/**
 * ファイルを共有する用モーダル
 * @param ModalProps
 * @returns J5x.Element
 */
const ShareFileModal: React.FC<ModalProps> = ({ showModal, handleClose, selectedProject, selectedFile }) => {
    const { t } = useTranslation();

    const [successMessage, setSuccessMessage] = useState<string>("");
    const [errorMessage, setErrorMessage] = useState<string>("");

    const [toProject, setToProject] = useState<string>("");
    const [toEmail, setToEmail] = useState<string>("");

    /**
     * 処理中フラグ
     *
     * ※多重クリックを防止するため、外部通信を伴うイベント処理では必ず使用すること
     */
    const [isProcessing, setIsProcessing] = useState<boolean>(false);

    const resetState = () => {
        setToEmail("");
        setSuccessMessage("");
        setErrorMessage("");
        setIsProcessing(false);
    };

    useEffect(() => {
        if (!showModal) {
            resetState();
        }
    }, [showModal]);

    /**
     * メールアドレス入力変更時の処理
     */
    const handleEmailChange: ChangeEventHandler<HTMLInputElement> = (event) => {
        setToEmail(event.target.value);
    };

    /**
     * プロジェクト名入力変更時の処理
     */
    const handleProjectNameChange: ChangeEventHandler<HTMLInputElement> = (event) => {
        setToProject(event.target.value);
    };

    const getFileName = (selectedFile: S3ProviderListOutputItem | undefined): string | undefined => {
        if (!selectedFile?.key) {
            return undefined;
        }
        return selectedFile.key.split("/").pop();
    };

    /**
     * 共有ボタン押下時の処理
     * @param event
     */
    const handleShareSubmit: FormEventHandler<HTMLFormElement> = async (event) => {
        event.preventDefault();
        setIsProcessing(true);
        setErrorMessage("");
        setSuccessMessage("");

        try {
            if (!selectedFile) {
                setErrorMessage(t("ErrorMessage.systemErrorMessage"));
                return;
            }
            if (!selectedFile || !selectedFile.key) {
                setErrorMessage(t("ErrorMessage.systemErrorMessage"));
                return;
            }
            const fileName = getFileName(selectedFile);
            if (!fileName) {
                setErrorMessage(t("ErrorMessage.systemErrorMessage"));
                return;
            }

            await shareFile(toEmail, selectedProject, toProject, fileName);
            setSuccessMessage(t("ShareFileModal.proccessSuccessed"));
        } catch (error: any) {
            loggingError("error updating user attributes", error);
            if (error.response && error.response.status === 404) {
                setErrorMessage(t("ShareFileModal.sharingFailed")); // 対象のアカウントが存在するかどうかの情報提供になってしまうため避けたい
            } else {
                setErrorMessage("ErrorMessage.systemErrorMessage" + error);
            }
        } finally {
            setIsProcessing(false);
        }
    };

    return (
        <Modal
            className="fixed left-1/2 top-1/2 w-4/5 max-w-2xl -translate-x-1/2 -translate-y-1/2 transform rounded-lg border bg-white p-4"
            overlayClassName="fixed inset-0 bg-black bg-opacity-50"
            isOpen={showModal}
        >
            <div className="m-0 items-center justify-center p-2 sm:m-2">
                <div className="my-1 text-center text-lg font-bold">{t("ShareFileModal.title")}</div>

                {/* 成功メッセージ */}
                {successMessage ? (
                    <div className="p-3 text-center text-green-500">
                        <p className="py-3 text-lg font-bold">{successMessage}</p>
                        <button className="btn-secondary mt-3 w-1/3" onClick={handleClose}>
                            {t("CommonLabel.close")}
                        </button>
                    </div>
                ) : isProcessing ? (
                    // 処理中メッセージ
                    <div className="p-2 text-center text-blue-500">
                        <p>{t("ShareFileModal.processing")}</p>
                    </div>
                ) : (
                    <>
                        {/* エラーメッセージ */}
                        {errorMessage && (
                            <div className="flex items-center justify-center pt-2">
                                <CrossCircleIcon />
                                <span className="pl-1 text-center font-bold text-red-600">
                                    {errorMessage.split("\n").map((line, index) => (
                                        <div key={index}>{line}</div>
                                    ))}
                                </span>
                            </div>
                        )}

                        {/* フォーム */}
                        <form onSubmit={handleShareSubmit}>
                            <div className="col-span-2 px-4 py-2">
                                <input
                                    type="email"
                                    name="email"
                                    id="email"
                                    placeholder={t("ShareFileModal.inputEMailPlaceholder")}
                                    className="textbox-common"
                                    value={toEmail}
                                    onChange={handleEmailChange}
                                    maxLength={EMAIL_MAX_LENGTH}
                                    required
                                    disabled={isProcessing}
                                />
                            </div>

                            <div className="col-span-2 px-4 py-2">
                                <input
                                    type="text"
                                    name="project_name"
                                    id="project_name"
                                    placeholder={t("ShareFileModal.inputProjectNamePlaceholder")}
                                    className="textbox-common"
                                    value={toProject}
                                    onChange={handleProjectNameChange}
                                    maxLength={PROJECT_NAME_MAX_LENGTH}
                                    required
                                    disabled={isProcessing}
                                />
                            </div>

                            <div className="max-h-screen overflow-y-auto p-2">
                                <ul className="list-disc pl-5 text-sm text-gray-800">
                                    <li>{t("ShareFileModal.shareNote1")}</li>
                                    <li>{t("ShareFileModal.shareNote2")}</li>
                                    <li>{t("ShareFileModal.shareNote3")}</li>
                                    <li>{t("ShareFileModal.shareNote4")}</li>
                                </ul>

                                <div className="mt-2 flex justify-center p-2">
                                    <button
                                        type="button"
                                        className="btn-secondary mx-4 w-1/3"
                                        onClick={handleClose}
                                        disabled={isProcessing}
                                    >
                                        {t("CommonLabel.cancel")}
                                    </button>
                                    <button type="submit" className="btn-primary mx-4 w-1/3" disabled={isProcessing}>
                                        {t("ShareFileModal.shareButton")}
                                    </button>
                                </div>
                            </div>
                        </form>
                    </>
                )}
            </div>
        </Modal>
    );
};

export default ShareFileModal;
