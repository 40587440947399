import { Storage } from "aws-amplify";
import { Auth, API } from "aws-amplify";
import { S3ProviderListOutputItem } from "@aws-amplify/storage";
import { loggingInfo, loggingWarn } from "../utils/LoggingUtils";
import { API_ENDPOINT_NAME } from "../consts/constants";
import {
    S3Client,
    ListObjectsV2Command,
    PutObjectCommand,
    GetObjectCommand,
    DeleteObjectCommand,
    CopyObjectCommand,
} from "@aws-sdk/client-s3";
import { getSignedUrl } from "@aws-sdk/s3-request-presigner";
import awsmobile from "../aws-exports";
import { Upload } from "@aws-sdk/lib-storage";
import { AxiosError } from "axios";

Storage.configure({
    customPrefix: {
        public: "",
    },
});

/**
 * ストレージサイズの取得
 *
 * @returns
 */
export const getStorageSize = async () => {
    const userData = await Auth.currentAuthenticatedUser();
    const idToken = userData.signInUserSession.idToken.jwtToken;
    const body = {
        request: {
            userAttributes: {
                id_token: idToken,
            },
        },
    };
    const path = `/s3_get_storage_size`;
    const request = {
        body: body,
        headers: {
            "Content-Type": "application/json; charset=UTF-8",
            Authorization: idToken,
        },
    };
    const response = await API.post(API_ENDPOINT_NAME, path, request);
    return response;
};

/**
 * ファイルの共有
 * @param from_email 共有元のメールアドレス
 * @param to_email 共有先のメールアドレス
 * @param from_project 共有元プロジェクト
 * @param to_project 共有先プロジェクト
 * @param file_name 対象ファイル名
 */
export const shareFile = async (to_email: string, from_project: string, to_project: string, file_name: string) => {
    try {
        const userData = await Auth.currentAuthenticatedUser();
        const from_email = userData.attributes.email;
        const idToken = userData.signInUserSession.idToken.jwtToken;
        const body = {
            request: {
                userAttributes: {
                    id_token: idToken,
                },
                from_email: from_email, 
                to_email: to_email,
                from_project: from_project,
                to_project: to_project,
                file_name: file_name,
            },
        };
        const path = `/s3_share_file`;
        const request = {
            body: body,
            headers: {
                "Content-Type": "application/json; charset=UTF-8",
                Authorization: idToken,
            },
        };
        console.log("API Request", API_ENDPOINT_NAME, path, userData);

        if (from_email === to_email) {
            throw new Error("You cannot share the project with yourself.");
        }

        const response = await API.post(API_ENDPOINT_NAME, path, request);
        return response;
    } catch (error) {
        const isAxiosError = (error: any): error is AxiosError => {
            return error?.isAxiosError === true;
        };

        if (isAxiosError(error)) {
            console.error("API Error:", {
                message: error.message,
                response: error.response?.data,
                status: error.response?.status,
            });
        } else if (error instanceof Error) {
            console.error("Error:", error.message);
        } else {
            console.error("Unexpected Error:", error);
        }

        throw error;
    }
};

/**
 * プロジェクトの共有
 * @param from_email ユーザのメールアドレス
 * @param to_email 共有先のメールアドレス
 * @param project_name フォルダ名（メールアドレスに続くプロジェクト名）
 */
export const shareProject = async (to_email: string, project_name: string) => {
    try {
        const userData = await Auth.currentAuthenticatedUser();
        const from_email = userData.attributes.email;
        const idToken = userData.signInUserSession.idToken.jwtToken;
        const body = {
            request: {
                userAttributes: {
                    id_token: idToken,
                },
                from_email: from_email,
                to_email: to_email,
                project_name: project_name,
            },
        };
        const path = `/s3_share_project`;
        const request = {
            body: body,
            headers: {
                "Content-Type": "application/json; charset=UTF-8",
                Authorization: idToken,
            },
        };

        if (from_email === to_email) {
            throw new Error("You cannot share the project with yourself.");
        }

        const response = await API.post(API_ENDPOINT_NAME, path, request);
        return response;
    } catch (error) {

        const isAxiosError = (error: any): error is AxiosError => {
            return error?.isAxiosError === true;
        };

        if (isAxiosError(error)) {
            console.error("API Error:", {
                message: error.message,
                response: error.response?.data,
                status: error.response?.status,
            });
        } else if (error instanceof Error) {
            console.error("Error:", error.message);
        } else {
            console.error("Unexpected Error:", error);
        }

        throw error;
    }
};

/**
 * STSのクレデンシャル取得
 * @returns
 */
export const getSTScredentials = async () => {
    const userData = await Auth.currentAuthenticatedUser();
    const idToken = userData.signInUserSession.idToken.jwtToken;
    const body = {
        request: {
            userAttributes: {
                id_token: idToken,
            },
        },
    };
    const path = `/s3_sts_generation`;
    const request = {
        body: body,
        headers: {
            "Content-Type": "application/json; charset=UTF-8",
            Authorization: idToken,
        },
    };
    const response = await API.post(API_ENDPOINT_NAME, path, request);
    const credentials = {
        accessKeyId: response.AccessKeyId,
        secretAccessKey: response.SecretAccessKey,
        sessionToken: response.SessionToken,
        email: response.email,
        groupId: response.groupId,
    };
    return credentials;
};

/**
 * ファイル一覧の取得
 * @param key キー
 * @returns
 */
export const getFileList = async (key: string, credentials: any) => {
    const userData = await Auth.currentAuthenticatedUser();
    const idToken = userData.signInUserSession.idToken.jwtToken;
    const body = {
        request: {
            userAttributes: {
                id_token: idToken,
            },
            prefix: key,
        },
    };
    const path = `/s3_get_file_list`;
    const request = {
        body: body,
        headers: {
            "Content-Type": "application/json; charset=UTF-8",
            Authorization: idToken,
        },
    };
    const contents = await API.post(API_ENDPOINT_NAME, path, request);
    if (contents) {
        return contents.map((elem: any) => {
            return { ...elem, key: elem.Key, size: elem.Size, lastModified: new Date(elem.LastModified) };
        });
    } else {
        return [];
    }
};

/**
 * フォルダの作成
 * @param email メールアドレス
 * @param folderName フォルダ名（メールアドレスに続くプロジェクト名）
 */
export const createFolder = async (folderName: string | undefined, credentials: any) => {
    const s3Client = new S3Client({
        region: awsmobile.aws_user_files_s3_bucket_region,
        credentials,
    });
    const command = new PutObjectCommand({
        Bucket: awsmobile.aws_user_files_s3_bucket,
        Key: `${folderName}/`, // フォルダを表すためにスラッシュを末尾に追加
    });
    await s3Client.send(command);
};

/**
 * S3からファイルをダウンロード
 * @param {*} key
 * @returns
 */
export const getFile = async (key: string, filename: string, credentials: any) => {
    const url = await getUrl(key, credentials);
    downloadBlob(url, filename);
};

/**
 * S3からファイルの画像URL取得
 * @param {*} key
 * @returns
 */
export const getUrl = async (key: string, credentials: any) => {
    const userData = await Auth.currentAuthenticatedUser();
    const idToken = userData.signInUserSession.idToken.jwtToken;
    const body = {
        request: {
            userAttributes: {
                id_token: idToken,
            },
            object_name: key,
        },
    };
    const path = `/s3_get_signed_url`;
    const request = {
        body: body,
        headers: {
            "Content-Type": "application/json; charset=UTF-8",
            Authorization: idToken,
        },
    };
    const contents = await API.post(API_ENDPOINT_NAME, path, request);
    return contents.presigned_url;
};

/**
 * S3からJSONの中身を取得
 * @param {*} key
 * @returns
 */
export const getJsonObject = async (key: string, credentials: any) => {
    const userData = await Auth.currentAuthenticatedUser();
    const idToken = userData.signInUserSession.idToken.jwtToken;
    const body = {
        request: {
            userAttributes: {
                id_token: idToken,
            },
            object_name: key,
        },
    };
    const path = `/s3_get_object`;
    const request = {
        body: body,
        headers: {
            "Content-Type": "application/json; charset=UTF-8",
            Authorization: idToken,
        },
    };
    const contents = await API.post(API_ENDPOINT_NAME, path, request);
    return contents;
};

/**
 * S3へファイルをアップロード
 * @param key
 * @param file
 * @returns
 */
export const putFile = async (key: string, file: File, credentials: any) => {
    const s3Client = new S3Client({
        region: awsmobile.aws_user_files_s3_bucket_region,
        credentials,
    });
    const upload = new Upload({
        client: s3Client,
        params: {
            Bucket: awsmobile.aws_user_files_s3_bucket,
            Key: key,
            Body: file,
        },
    });

    upload.on("httpUploadProgress", (progress) => {
        loggingInfo(progress); // 進行状況をログに出力
    });

    await upload.done();
};

/**
 * S3からファイルを削除
 * @param {*} key
 * @returns
 */
export const removeFile = async (key: string, credentials: any) => {
    const s3Client = new S3Client({
        region: awsmobile.aws_user_files_s3_bucket_region,
        credentials,
    });
    const command = new DeleteObjectCommand({
        Bucket: awsmobile.aws_user_files_s3_bucket,
        Key: key,
    });
    await s3Client.send(command);
};

/**
 * ファイルリスト取得結果からプロジェクト名一覧を抽出する。
 *
 * ※S3の対象バケットにて、
 * バケット名/[メールアドレス]/[プロジェクト名]/[ファイル名]
 * の構成でファイルが保存されているとする。
 * @param {*} fileList
 * @returns
 */
export const extractProjects = (fileList: any[], startFolder: string) => {
    const projectList: string[] = [];
    fileList
        .map((res) => {
            return res.key.replace(startFolder, "").split("/")[1];
        })
        .forEach((res) => {
            const projectName = res;
            if (projectName && !projectList.includes(projectName)) {
                projectList.push(projectName);
            }
        });
    return projectList;
};

/**
 * ファイルリスト取得結果から指定したプロジェクト配下のファイルを抽出する
 *
 * @param {*} fileList
 * @param {*} projectName
 * @returns
 */
export const extractFilesByProject = (fileList: S3ProviderListOutputItem[], projectName: string) => {
    const newList: S3ProviderListOutputItem[] = [];
    fileList
        .filter((res) => res.size && res.size > 0) // ファイルに限定
        .filter((res) => projectName == res.key?.split("/")[1] || projectName == res.key?.split("/")[2]) // プロジェクト名で絞り込み
        .forEach((res) => {
            newList.push(res);
        });
    return newList;
};

/**
 * S3から取得したファイル情報からダウンロードを実行
 *
 * @param url
 * @param filename
 * @returns
 */
export function downloadBlob(url: string, filename: string) {
    // const url = URL.createObjectURL(blob);
    const a = document.createElement("a");
    a.href = url;
    a.download = filename || "download";
    const clickHandler = () => {
        setTimeout(() => {
            URL.revokeObjectURL(url);
            a.removeEventListener("click", clickHandler);
        }, 150);
    };
    a.addEventListener("click", clickHandler, false);
    a.click();
    return a;
}

/**
 * ファイルのコピー
 */
export async function copyFile(fromPath: string, toPath: string, credentials: any) {
    const s3Client = new S3Client({
        region: awsmobile.aws_user_files_s3_bucket_region,
        credentials,
    });
    const copyParams = {
        CopySource: `${awsmobile.aws_user_files_s3_bucket}/${encodeURIComponent(fromPath)}`,
        Bucket: awsmobile.aws_user_files_s3_bucket,
        Key: toPath,
    };
    await s3Client.send(new CopyObjectCommand(copyParams));
}

/**
 * ファイルの移動
 */
export async function moveFile(fromPath: string, toPath: string, credentials: any) {
    await copyFile(fromPath, toPath, credentials);
    await removeFile(fromPath, credentials);
}
