import { useTranslation } from "react-i18next";
import Modal from "react-modal";
import { ChangeEventHandler, FormEventHandler, useEffect, useState, useRef } from "react";
import { getSTScredentials } from "../../clients/S3ClientNew";
import { CrossCircleIcon } from "../../components/Icons";
import { S3ProviderListOutputItem } from "@aws-amplify/storage";

type ModalProps = {
    showModal: boolean;
    handleClose: () => void;
    selectedFile: S3ProviderListOutputItem | undefined;
    selectedProject: string;
    projectList: string[];
    moveFileToOtherProject: (selectedToProject: string, credentials: any) => void;
    isProcessing: boolean;
    errorMessage: string;
    setErrorMessage: (errorMessage: string) => void;
};

/**
 * プロジェクト間ファイル移動用モーダル
 * @param ModalProps
 * @returns J5x.Element
 */
const TransfarFilesetweenProjectsModal: React.FC<ModalProps> = ({
    showModal,
    handleClose,
    selectedProject,
    selectedFile,
    projectList,
    moveFileToOtherProject,
    isProcessing,
    errorMessage,
    setErrorMessage,
}) => {
    const { t } = useTranslation();
    const [credentials, setCredentials] = useState<any>(null);
    const [selectedToProject, setSelectedToProject] = useState<string>("");

    useEffect(() => {
        const initCredential = async () => {
            if (!credentials) {
                const cred = await getSTScredentials();
                setCredentials(cred);
            }
        };
        initCredential();
    }, [credentials]);

    // モーダルを開くタイミングのリセット操作
    useEffect(() => {
        setErrorMessage("");
        setSelectedToProject("");
    }, [showModal]);

    /**
     * 移動ボタン押下時の処理
     * @param event
     */
    const handleTransfarSubmit: FormEventHandler<HTMLFormElement> = (event) => {
        event.preventDefault();
        moveFileToOtherProject(selectedToProject, credentials);
    };

    /**
     * プロジェクト選択時のイベント処理
     */
    const handleProjectChange: ChangeEventHandler<HTMLSelectElement> = (event) => {
        setErrorMessage("");
        const pname: string = event.target.value;
        setSelectedToProject(pname);
    };

    return (
        <Modal
            className="fixed left-1/2 top-1/2 w-4/5 max-w-md -translate-x-1/2 -translate-y-1/2 transform rounded-lg border bg-white p-4"
            overlayClassName="fixed inset-0 bg-black bg-opacity-50"
            isOpen={showModal}
        >
            <div className="m-0 items-center justify-center p-2 sm:m-2">
                <div className="my-1 text-center text-lg font-bold">{t("TransfarFileModal.title")}</div>

                {isProcessing ? (
                    // 処理中メッセージ
                    <div className="p-2 text-center text-blue-500">
                        <p>{t("TransfarFileModal.processing")}</p>
                    </div>
                ) : (
                    <>
                        {/* エラーメッセージ */}
                        {errorMessage && (
                            <div className="flex items-center justify-center pt-2">
                                <CrossCircleIcon />
                                <span className="pl-1 text-center font-bold text-red-600">
                                    {errorMessage.split("\n").map((line, index) => (
                                        <div key={index}>{line}</div>
                                    ))}
                                </span>
                            </div>
                        )}

                        {/* フォーム */}
                        <form onSubmit={handleTransfarSubmit}>
                            <div className="col-span-2 py-2 pl-2">
                                <p>
                                    {t("TransfarFileModal.fineNameLabel")}
                                    {selectedFile?.key?.split("/").pop()}
                                </p>
                            </div>

                            {/* プロジェクト選択 */}
                            <div className="mx-auto flex max-w-lg items-center justify-center py-2">
                                <select
                                    name="project"
                                    id="project"
                                    className="textbox-common"
                                    value={selectedToProject}
                                    onChange={handleProjectChange}
                                >
                                    <option hidden>{t("DataManagement.selectProject")}</option>
                                    {projectList
                                        .filter((p) => p != selectedProject)
                                        .map((p, index) => (
                                            <option key={index} value={p}>
                                                {p}
                                            </option>
                                        ))}
                                </select>
                            </div>
                            <div className="mt-2 flex justify-center p-2">
                                <button
                                    type="button"
                                    className="btn-secondary mx-1 w-1/3"
                                    onClick={handleClose}
                                    disabled={isProcessing}
                                >
                                    {t("CommonLabel.cancel")}
                                </button>
                                <button
                                    type="submit"
                                    className="btn-primary mx-1 w-1/3"
                                    disabled={isProcessing || selectedToProject == ""}
                                >
                                    {t("CommonLabel.move")}
                                </button>
                            </div>
                        </form>
                    </>
                )}
            </div>
        </Modal>
    );
};

export default TransfarFilesetweenProjectsModal;
