import { useTranslation } from "react-i18next";
import Modal from "react-modal";
import { useEffect, useState } from "react";
import { getStorageSize } from "../../clients/S3ClientNew";
import { CrossCircleIcon } from "../../components/Icons";
import ProgressBar from "../../components/ProgressBar";

/** 最大ストレージ容量 */
const MAX_STORAGE_SIZE: number = 15 * 1024 * 1024 * 1024; // 15GB in bytes

type ModalProps = {
    showModal: boolean;
    handleClose: () => void;
};

/**
 * ストレージ使用量を確認するモーダル
 * @param ModalProps
 * @returns J5x.Element
 */
const CheckStorageUsageModal: React.FC<ModalProps> = ({ showModal, handleClose }) => {
    const { t } = useTranslation();

    const [errorMessage, setErrorMessage] = useState<string>("");

    const [capacity, setCapacity] = useState<string>("0"); // storage 容量
    const [usage, setUsage] = useState<string>("0"); // 使用量
    const [available, setAvailable] = useState<string>("0"); // 空き容量
    const [progress, setProgress] = useState(0);

    /**
     * 処理中フラグ
     *
     * ※多重クリックを防止するため、外部通信を伴うイベント処理では必ず使用すること
     */
    const [isProcessing, setIsProcessing] = useState<boolean>(false);

    const resetState = () => {
        setErrorMessage("");
        setIsProcessing(false);
        setProgress(0);
    };

    useEffect(() => {
        if (!showModal) {
            resetState();
        } else {
            setProgress(0);
            setIsProcessing(true);

            // 非同期関数を作成して実行
            const fetchStorageSize = async () => {
                try {
                    const response = await getStorageSize();
                    const myfolder = response.used?.myfolder || 0;
                    const fixedAvailable = Math.max(0, MAX_STORAGE_SIZE - myfolder);

                    setCapacity(convertByteToGB(MAX_STORAGE_SIZE));
                    setUsage(convertByteToGB(myfolder));
                    setAvailable(convertByteToGB(fixedAvailable));

                    const progressValue = (myfolder / MAX_STORAGE_SIZE) * 100;
                    setProgress(progressValue);

                    console.log("exact usage: ", response.used?.myfolder, "Byte");
                } catch (error) {
                    console.error("Error fetching storage size:", error);
                    setErrorMessage("ErrorMessage.systemErrorMessage"+error);
                } finally {
                    setIsProcessing(false);
                }
            };
    
            fetchStorageSize(); // 非同期関数を実行
        }
    }, [showModal]);

    function convertByteToGB(number: number): string {
        return (number / 1024 / 1024 / 1024).toFixed(1);
    }

    return (
        <Modal
            className="fixed left-1/2 top-1/2 w-2/5 max-w-2xl -translate-x-1/2 -translate-y-1/2 transform rounded-3xl border bg-white p-1"
            overlayClassName="fixed inset-0 bg-black bg-opacity-50"
            isOpen={showModal}
        >
            <div className="m-0 items-center justify-center p-2 sm:m-2">
                <div className="my-1 text-center text-2xl font-bold">{t("CheckStorageUsageModal.title")}</div>

                {isProcessing ? (
                    // 処理中メッセージ
                    <div className="p-2 text-center text-blue-500">
                        <p>{t("CheckStorageUsageModal.processing")}</p>
                    </div>
                ) : (
                    <>
                        {/* エラーメッセージ */}
                        {errorMessage && (
                            <div className="flex items-center justify-center pt-2">
                                <CrossCircleIcon />
                                <span className="pl-1 text-center font-bold text-red-600">
                                    {errorMessage.split("\n").map((line, index) => (
                                        <div key={index}>{line}</div>
                                    ))}
                                </span>
                            </div>
                        )}

                        <div className="px-10 py-5">
                            <ProgressBar progress={progress} />
                        </div>
                        <div className="grid grid-cols-2 text-lg">
                            {/* ストレージ容量 */}
                            <div className="text-right">{t("CheckStorageUsageModal.capacity")}：</div>
                            <div className="text-left">{capacity} GB</div>
                            {/* 使用量 */}
                            <div className="text-right">{t("CheckStorageUsageModal.usage")}：</div>
                            <div className="text-left">{usage} GB</div>
                            {/* 空き容量 */}
                            <div className="text-right">{t("CheckStorageUsageModal.available")}：</div>
                            <div className="text-left">{available} GB</div>
                        </div>

                        <div className="max-h-screen overflow-y-auto p-2">

                            <div className="mt-2 flex justify-center p-2">
                                <button
                                    type="button"
                                    className="btn-secondary mx-4 w-1/3"
                                    onClick={handleClose}
                                    disabled={isProcessing}
                                >
                                    {t("CommonLabel.close")}
                                </button>
                            </div>
                        </div>
                    </>
                )}
            </div>
        </Modal>
    );
};

export default CheckStorageUsageModal;