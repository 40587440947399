import React from "react";

interface ProgressBarProps {
    progress: number;
}

const ProgressBar: React.FC<ProgressBarProps> = ({ progress }) => {
    const clampedProgress = Math.max(0, Math.min(progress, 100));
    return (
        <div className="w-full bg-gray-200 h-8">
        <div
            className="bg-blue-700 h-8"
            style={{ width: `${clampedProgress}%`, backgroundColor: '#215F9A' }}
        ></div>
        </div>
    );
};

export default ProgressBar;